@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

/*  ===== html + body ===== */
html, body {
  font-family: 'JetBrains Mono', monospace;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  color: #c6c6cb;
}

/*  ===== Globally acessible ===== */
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}
.gradient {
  background-image: linear-gradient(84.37deg,#0081b3 2.3%,#0ff 34.46%,#6fef2d 67.18%);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
h1, p {
  max-width: 100%;
}
h1 {
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 2vh;
}
h5.title {
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 2vh;
}
p {
  margin: 0;
  font-size: 1rem;
  margin-bottom: 3vh;
  padding: 2vh;
}
.title {
  color: #0081b3;
  font-size: 2rem;
  margin-top: 1vh;
}
.tagline {
  margin: 0;
  margin-bottom: 4vh;
}

/*  ===== AuthFlow ===== */
.authflow {
  justify-content: center;
  width: 100%;
}
.flow-shown {  
  width: 100%;
}
.flow-shown form {
  width:90%;
}
.rounded-top {
  border-radius: 14px;
}

/*  ===== Bottom Navbar ===== */
.bottom-nav {
  margin-top: 10vh;
  background: #121217
}
.bottom-title {
  margin: 0;
  font-size: 1.2rem;
  color: rgb(33, 33, 33);
  padding-top: 3vh;
  padding-bottom: 3vh;
}




/*  ===== Navbar ===== */
.logo-link {
  width: 10vh;
  height: 10vh;
  padding: 2vh;
  padding-left: 0.9vh;
  background-color: #121217;
  border-bottom-right-radius: 100%;
}
.logo {
  margin: 3px;
  width: 7vh;
  height: auto;
  display: flex;
}
.navbar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

